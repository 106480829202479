
import { defineComponent, PropType } from "vue-demi";

export default defineComponent({
  name: 'Tariff',
  props: {
    tariff: {
      type: Object // as PropType<Tariff.Dto>,
      // required: true
    }
  }
})
