
import { defineComponent } from "vue-demi";
import Tariff from "@/components/tariff/index.vue";

export default defineComponent({
  name: "TariffView",
  props: {
    _id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tariff: null,
    }
  },
  created() {
    this.getMethod();
  },
  methods: {
    async getMethod() {
      // const result = await useAPI().tariff.getMethod(this._id);
      return {};
    }
  },
  components: {
    Tariff,
  },
});
